module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"loading":"lazy","showCaptions":true,"disableBgImage":false,"withWebp":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Ballywalter Park","language":"en","description":"Ballywalter Park is the home of Lord & Lady Dunleath and it has been in their family for 170 years.","canonical":"https://www.ballywalterpark.co.uk/","openGraph":{"type":"website","locale":"en_US","url":"https://www.ballywalterpark.co.uk/","description":"Ballywalter Park is the home of Lord & Lady Dunleath and it has been in their family for 170 years.","title":"Ballywalter Park","site_name":"Ballywalter Park"},"twitter":{"site":"@Ballywalterpark","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-353638537"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ballywalter Park","short_name":"Ballywalter Park","start_url":"/","background_color":"#fbfbfb","theme_color":"#fbfbfb","display":"minimal-ui","icon":"static/assets/icons/logo512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"140806e47371d9c86cbe83c87c8d4dc3"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
